/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://areedwtasvfivh6domc6jiijmu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ovgtvlwb4rbefopjyvjz5jdb3m",
    "aws_cognito_identity_pool_id": "us-west-2:fb3f6992-d0ac-45d1-bafb-237c05fd5a5a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_uArXfdHs3",
    "aws_user_pools_web_client_id": "60ridjgpf14tro0pkfrf16ff85",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "losty-storage01553-staging172948-main",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
